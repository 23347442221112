import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"

import gql from "graphql-tag"
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import TopBar from "../components/PropertyDetails/TopBar/TopBar";
import Banner from "../components/PropertyDetails/DetailsBanner/Banner";
import ViewPage from "../components/PropertyDetails/ViewPage/ViewPage";
import ErrorPage from '../pages/404'
import logoBlack from "../images/logo-dark.svg";
import { navigate } from "@reach/router"
const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    newDevelopments(where:{id: $id}) {
      id
      development_id
      development_title 
      min_price
      max_price
      price_prefix
      images
      imagetransforms  
      latitude
      longitude 
      short_description
      long_description
      office_crm_id 
      sub_heading_2 
      developer_logo
      bedrooms
      banner_video
      floorplan 
      brochure
      telephone
    }
  }
`;


const PropertyDetailsTemplate = (props) => {

  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");


  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }, context: { clientName: "feed" }
  });
  // 
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
      </div>
    </section>
  );

  // console.log("ccc",property_details)

  // if (property_details?.newDevelopments.length == 0) {
  //   navigate('/new-homes-for-sale/')
  // }
  return (
    <React.Fragment>
      <div className="wrapper property-details-wrapper">
        {property_details && property_details.newDevelopments && property_details.newDevelopments.length > 0 ? property_details.newDevelopments.map((data, i) => {
          const metaTitle = `New homes for sale with ${data.bedrooms.toString()} bedrooms in ${data.development_title} at ${data.price_prefix} £${data.min_price.toLocaleString()} - £${data.max_price.toLocaleString()} | Arun Estates`
          const metaDesc = `Find the details of New homes for sale with ${data.bedrooms.toString()} bedrooms in ${data.development_title} at ${data.price_prefix} £${data.min_price.toLocaleString()} - £${data.max_price.toLocaleString()} with Arun Estates. Request a viewing to get assistance in buying this new build homes.`
          return (
            <>
              <Header />
              <SEO title={metaTitle}  description={metaDesc} image={data.images ? data.images[0].srcUrl : '' }/>
              <TopBar />
              <Banner propertyData={data} />
              <ViewPage propertyData={data} />
              {/* <SimilarProperty propertyData={data} /> */}
              <Footer />
            </>
          )
        }) : <ErrorPage />}
      </div>
    </React.Fragment>
  )
}

export default PropertyDetailsTemplate;
